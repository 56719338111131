<template>
  <div id="scraper-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-globe"></i>
          <span style="margin-left: 5px"> Log Scraper Tier 1 </span>
        </b-card-title>
      </b-card-header>
      <b-card-header class="py-0">
        <b-card-sub-title>
          Daftar media yang tidak mempublikasikan berita dalam
          {{
            hour_range >= 24 ? `${hour_range / 24} Hari` : `${hour_range} Jam`
          }}
        </b-card-sub-title>
      </b-card-header>

      <!-- body component -->
      <b-card-body
        class="d-flex flex-wrap gap-5px justify-content-end align-items-center pb-0"
      >
        <!-- hour filter -->
        <div>
          <select
            v-model="hour_range"
            id="hour_range"
            class="form-control "
            @change="getData()"
          >
            <option value="3">3 Jam</option>
            <option value="6">6 Jam</option>
            <option value="24">1 Hari</option>
            <option value="168">7 Hari</option>
          </select>
        </div>
        <!-- keyword filter -->
        <b-input-group class="input-group-merge " style="width: 20rem">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Masukkan kata kunci..."
            v-model="table_data.filter"
          />
        </b-input-group>
      </b-card-body>
      <div class="mt-1">
        <!-- loader component -->
        <loader v-if="is_loading" />
        <!-- table component -->
        <dataTableClientPagination
          v-else
          :data="table_data"
          :pagination="pagination"
          :is_row_custom_color="true"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dataTableClientPagination from "@/views/components/dataTableClientPagination.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  name: "LogScraperTier1",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    dataTableClientPagination,
    loader,
  },
  data() {
    return {
      is_loading: true,
      hour_range: 6,
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        filter: "",
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "duration",
            label: "durasi",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
          {
            key: "count",
            label: "jumlah",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { paddingRight: "2rem" },
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      useJwt.axiosIns
        .get("scraper/log-streams?hour_range=" + this.hour_range)
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data.scraper_data || [];
          this.pagination.count = this.table_data?.items?.length || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
  },
};
</script>
